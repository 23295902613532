<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col lg="3">
              <h1>
                <feather-icon icon="BookOpenIcon" size="30" class="mr-50" />
                Escolas
              </h1>
            </b-col>
            <b-col lg="6">
              <b-form @submit.prevent="fetchSchools">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filtro.name"
                    placeholder="Buscar Escola"
                  />
                </b-input-group>
              </b-form>
            </b-col>
            <b-col lg="3">
              <b-button
                variant="primary button-right"
                onclick="location.href='/NovaEscola'"
              >
                Adicionar Escola</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <div class="d-flex">
      <b-pagination
        @input="onChangePage"
        class="ml-auto"
        v-model="currentPage"
        :total-rows="fetchedSchools.totalItems"
        :per-page="perPage"
      ></b-pagination>
    </div>
    <b-card no-body>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchedSchools.content"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Status -->
        <template #cell(active)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.active)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusText(data.item.active) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'listaTurmas', params: { id: data.item.id} }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Listar Turmas</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'escolasEdit', params: { id: data.item.id} }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar Escola</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>
      </b-table>
    </b-card>
    <div class="d-flex">
      <b-pagination
        class="ml-auto"
        v-model="fetchedSchools.currentPage"
        :total-rows="fetchedSchools.totalItems"
        :per-page="fetchedSchools.perPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import schoolService from '@/services/schoolService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import {
BAvatar, BBadge, BButton, BCard, BCol, BDropdown,
BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BLink,
BMedia, BPagination, BRow, BTable
} from 'bootstrap-vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BButton,
    BForm,
    flatPickr,
  },
  data: () => ({
    configFlatPickr: { dateFormat: 'd/m/Y', locale: Portuguese },
    filtro: {
      name: '',
    },
    perPage: 10,
    totalUsers: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchQuery: '',
    sortBy: 'id',
    isSortDirDesc: true,
    roleFilter: null,
    planFilter: null,
    statusFilter: null,
    fetchedSchools: [],
    tableColumns: [
      { key: 'name', label: 'Nome', sortable: true },
      { key: 'address', label: 'Endereço', sortable: true },
      { key: 'city', label: 'Município', sortable: true },
      { key: 'neighborhood', label: 'Distrito Sanitário', sortable: true },
      { key: 'nameDirector', label: 'Diretor', sortable: true },
      { key: 'active', label: 'Status', sortable: true },
      { key: 'actions', label: 'Acesso', sortable: false },
    ],
  }),
  mounted() {
    this.fetchSchools()
  },
  methods: {
    onChangePage() {
      this.fetchSchools()
    },
    prepareRequest(obj) {
      return {
        ...obj,
        page: this.currentPage,
        itemsPerPage: this.perPage,
      }
    },
    async fetchSchools() {
      showSpinner()
      this.fetchedSchools = await schoolService.getAll(
        this.prepareRequest(this.filtro),
      )
      hideSpinner()
    },
    // resolveUserRoleVariant: role => {
    //   if (role === 'paciente') return 'primary'
    //   if (role === 'author') return 'warning'
    //   if (role === 'maintainer') return 'success'
    //   if (role === 'editor') return 'info'
    //   if (role === 'admin') return 'danger'
    //   return 'primary'
    // },
    // resolveUserRoleIcon: role => {
    //   if (role === 'paciente') return 'UserIcon'
    //   if (role === 'author') return 'SettingsIcon'
    //   if (role === 'maintainer') return 'DatabaseIcon'
    //   if (role === 'editor') return 'Edit2Icon'
    //   if (role === 'admin') return 'ServerIcon'
    //   return 'UserIcon'
    // },
    resolveUserStatusText: status => {
      return status ? 'Ativo' : 'Inativo'
    },

    resolveUserStatusVariant: status => {
      return status ? 'success' : 'danger'
    },
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
